(function($) {
  $(function() {
    $('#contact_new_form_contact_type').on('change', function() {
      $('.js-content-all').hide();
      var val = $(this).val().toLowerCase();
      if (val) {
        $('.js-content-' + val).show();
      }
    }).trigger('change');
  });
})(jQuery);
