//= require slick-carousel/slick/slick.js

var slickConf = {
  bannerhome: {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    swipe: false,
    prevArrow: null,
    nextArrow: null,
  },
  banner: {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    swipe: false,
    prevArrow: null,
    nextArrow: null,
  }
};

var slickInit = function() {
  $('.c-slick').each(function() {
    // return;
    var $this = $(this);

    var component = $this.data('slick-component');
    var $component = $this.closest('.c-' + component);
    var slick_class = 'c-' + component + '--slick';
    if ($component.hasClass(slick_class)) return;
    $component.addClass(slick_class);

    $this.slick($.extend({
      dots: false,
      infinite: false,
      speed: 300,
      // prevArrow: $this.prev(),
      // nextArrow: $this.next(),
    }, slickConf[component]));
  });
};

$(slickInit);
