(function($) {
  $(function() {

    $('.js-video-player button').on('click', function(e) {
      e.preventDefault();

      var $player = $(this).closest('.js-video-player');
      var $embed = $player.next();

      var videoMarkup = $embed.data('video')
      if (videoMarkup) {
        $embed.html(videoMarkup);
      }
      else {
        $embed.show();
      }

      $player.remove();
    });

  });
})(jQuery);
