//= require masonry-layout/dist/masonry.pkgd.js
//= require imagesloaded/imagesloaded.pkgd.js

// layout Masonry after each image loads
var masonryImagesLoaded = function() {
  $('.js-masonry').imagesLoaded().progress(function() {
    $('.js-masonry').masonry('layout');
  });
};

var masonryInit = function() {
  $('.c-gallery:not(.c-gallery--masonry) .js-masonry').each(function() {
    var $this = $(this);
    $this.parent().addClass('c-gallery--masonry');

    $this.masonry({
      percentPosition: true,
      columnWidth: '.c-gallery__item--sizer',
      itemSelector: '.c-gallery__item'
    });
  });
};

(function($) {
  $(function() {
    masonryInit();
    masonryImagesLoaded();
  });
})(jQuery);
